// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui-pro/scss/coreui";

// If you want to add something do it here
@import "custom";

@import "node_modules/angular2-toaster/toaster";

@import "../../node_modules/ag-grid-enterprise/dist/styles/ag-grid.scss";
@import "../../node_modules/ag-grid-enterprise/dist/styles/ag-theme-alpine.css";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/spinkit.css";

// spinners
.sk-fading-circle .sk-circle {
  margin: 0 auto;
}
.sk-three-bounce {
  height: 40px;
}
