// Here you can add other styles

.bg-pharos {
  background-color: #652082 !important;
}

.btn-pharos {
  background-color: #f9b130 !important;
  border-color: #fda90d !important;
}

.btn-login {
  background-color: #652082 !important;
  border-color: #652082 !important;
}

.text-password-reset {
  color: #652082 !important;
  text-decoration: underline !important;
}

// Naviagtion bar
.app-header {
  position: relative;
  flex-direction: row;
  height: 55px;
  padding: 0;
  margin: 0;
  background-color: #652082;
  border-bottom: 1px solid #40015c;
}

.nav-link .text-muted {
  color: #ffcf76;
}

// .text-nav-bar {
//   color: #f9b130;
// }

.app-header .nav-item .nav-link {
  color: #f9b130;
}

.app-header .nav-item .nav-link:hover,
.app-header .nav-item .nav-link:focus {
  color: #ffcf76;
  text-decoration: none;
}

.sidebar .nav-link.active .nav-icon {
  color: #f9b130;
}

// need to figure this color out for the sandwich icon
.navbar-toggler-icon {
  color: #f9b130;
}

// Modal Styles
.modal-primary .modal-header {
  background-color: #652082;
}

.ng-reflect-ng-class:hover {
  background-color: #f9b130;
}
